import api from "@/api/api";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    character: null,
  }),
  computed: {
    ...mapGetters({
      getUserId: "user/getUserId",
    }),
    isCharacter() {
      return !!this.character;
    },
    isInGame() {
      return this.character?.isInGame;
    },
    isOnMarket() {
      return this.character?.isOnMarket;
    },
    nftMarketId() {
      return this.character?.nftMarketId;
    },
    nextBreedingDateCalculated() {
      return this.isCharacter
        ? this.character.nextBreedingDateCalculated
        : null;
    },
    breedingAmount: {
      get() {
        return this.isCharacter ? this.character.breedingAmount : null;
      },
      set(val) {
        this.character.breedingAmount = val;
      },
    },
    isCalculatedBreedingAmounts() {
      return this.isCharacter && !!this.character.calculatedBreedingAmounts;
    },
    cshExplorerLink() {
      return `https://mumbai.polygonscan.com/token/0x08183FD16f7E80E83485DAA87f78909131434A59?a=${this.character?.NFTid}`;
    },
    characterIgmCommission() {
      return this.isCalculatedBreedingAmounts
        ? this.character.calculatedBreedingAmounts.igmCommission
        : 0;
    },
    characterSubCommission() {
      return this.isCalculatedBreedingAmounts
        ? this.character.calculatedBreedingAmounts.subCommission
        : 0;
    },
    characterCreatedAt() {
      return this.isCharacter ? this.character.createdAt : "";
    },
    characterPhenotypeRarity() {
      return this.isCharacter
        ? Object.entries(this.character.phenotypeRarity).map(
            ([key, value]) => ({
              title: key,
              rarity: value.rarity,
              race: value.race,
            })
          )
        : [];
    },
    characterImage() {
      return this.isCharacter ? this.character.image : "";
    },
    characterName() {
      return this.isCharacter ? this.character.name : "";
    },
    characterNameParsed() {
      return this.isCharacter ? this.character.name.split(",") : ["", ""];
    },
    characterSkills() {
      return this.isCharacter ? this.character.skills : {};
    },
    nftId() {
      return this.isCharacter ? this.character.NFTid : "";
    },
    hatchedBy() {
      return this.isCharacter ? this.character.hatchedBy : "";
    },
    nextUpgradeAmount() {
      return this.isCharacter ? this.character.nextUpgradeAmount : null;
    },
    characterId() {
      return this.isCharacter ? this.character.id : "";
    },
    characterAvailableSkillPoints: {
      get() {
        if (!this.nextUpgradeAmount) {
          return 0;
        }
        return this.isCharacter ? this.character.skillPoints : 0;
      },
      set(val) {
        this.character.skillPoints = val;
      },
    },
    igmUpgradeAvailable() {
      return this.isCharacter ? this.character.igmUpgradeAvailable : false;
    },
    subUpgradeAvailable() {
      return this.isCharacter ? this.character.subUpgradeAvailable : false;
    },
    isCshParents() {
      return this.isCharacter && this.character.parents[0];
    },
    cshPhenotype() {
      return this.isCharacter ? this.character.phenotype : [];
    },
    cshFirstParentPhenotype() {
      return this.isCshParents ? this.character.parents[0].phenotype : null;
    },
    cshSecondParentPhenotype() {
      return this.isCshParents ? this.character.parents[1].phenotype : null;
    },
    isCshOwner() {
      return this.character?.ownerId === this.getUserId;
    },
    aliensRacePercent() {
      return this.character?.race?.aliens;
    },
    animalsRacePercent() {
      return this.character?.race?.animals;
    },
    creaturesRacePercent() {
      return this.character?.race?.creatures;
    },
    cshParentImgFirst() {
      return this.cshFirstParentPhenotype
        ? `${window.location.origin}/cs/${
            this.cshFirstParentPhenotype.wings
          }.${this.cshFirstParentPhenotype.tail}.${
            this.cshFirstParentPhenotype.arms
          }.${this.cshFirstParentPhenotype.legs}.${
            this.cshFirstParentPhenotype.body
          }.${this.cshFirstParentPhenotype.eyes}.${
            this.cshFirstParentPhenotype.face
          }.${this.cshFirstParentPhenotype.accessory}.${
            this.cshFirstParentPhenotype.color[0]
          }.${this.cshFirstParentPhenotype.color[1]}.svg`
        : null;
    },
    cshParentImgSecond() {
      return this.cshSecondParentPhenotype
        ? `${window.location.origin}/cs/${
            this.cshSecondParentPhenotype.wings
          }.${this.cshSecondParentPhenotype.tail}.${
            this.cshSecondParentPhenotype.arms
          }.${this.cshSecondParentPhenotype.legs}.${
            this.cshSecondParentPhenotype.body
          }.${this.cshSecondParentPhenotype.eyes}.${
            this.cshSecondParentPhenotype.face
          }.${this.cshSecondParentPhenotype.accessory}.${
            this.cshSecondParentPhenotype.color[0]
          }.${this.cshSecondParentPhenotype.color[1]}.svg`
        : null;
    },
    cshParentNameFirst() {
      return this.isCshParents ? this.character.parents[0].name : "";
    },
    cshParentNameSecond() {
      return this.isCshParents ? this.character.parents[1].name : "";
    }
  },
  methods: {
    isCharacterAllowedForBreeding(nextBreedingDateCalculated) {
      if (!nextBreedingDateCalculated) return true;
      let date = new Date(nextBreedingDateCalculated);
      return date.getTime() < Date.now();
    },
    async getCryptoshoppiesById(id) {
      try {
        this.character = await api.getCryptoshoppiesById(id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
