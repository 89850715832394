<template>
  <div class="char model-shadow">
    <div class="char-preloader" v-if="!image">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke="#FCFCFC"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-dasharray="3 4"
        />
      </svg>
    </div>
    <img :src="image" alt="" v-if="image" />
    <img
      class="char__holder"
      src="@/assets/img/svg/shoppie-placeholder.svg"
      alt=""
      v-if="!image"
    />
  </div>
</template>

<script>
export default {
  name: "CharacterModel",
  props: {
    image: {
      type: String,
      required: false,
      // default: () => require("@/assets/img/dummie.png"),
    },
  },
};
</script>
